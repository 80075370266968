import React from "react"

import SEO from "../../components/SEO"
// import { Link } from "gatsby"
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faHeart,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"
import { Count } from "../../components/Home/Count";

import brillea_cloud from "../../../static/images/2/home/brillea_cloud.svg";

import home_header_bk from "../../../static/images/2/home/header_bk.svg"

import trueup_section_bk_left from "../../../static/images/2/home/trueup_section_bk_left.svg"
import trueup_section_bk_right from "../../../static/images/2/home/trueup_section_bk_right.svg"
import AOS from 'aos';

import clever_strategies_bk from "../../../static/images/2/help/image_adcreation.svg"
import clever_strategies_bk_MOB from "../../../static/images/2/help/image_adcreation_MOB.svg"
import marketing_expert from "../../../static/images/2/help/marketing_expert.png"


import EventbriteButton from '../../components/react-eventbrite-popup-checkout';

import LVMH from '../../../static/images/2/logos/trueup_clients/LVMH_colour.png';
import SMARTY from '../../../static/images/2/logos/trueup_clients/smarty_colour.png';
import MOONPIG from '../../../static/images/2/logos/trueup_clients/moonpig_colour.png';
import TELEFONICA from '../../../static/images/2/logos/trueup_clients/telefonica_colour.png';

import seoImage from '../../../static/images/2/webinar/dec-2020/facebook.jpg';

const Dec = ({pageContext}) => {

    const webinarEventId = (pageContext?.webinarEventId ? pageContext?.webinarEventId : '129884444871');

    React.useEffect(() => {
        AOS.init();
    },[]);
// console.log("path___", path)
    return (
      <>
        <SEO
          title="brillea - helping purpose-driven startups launch & grow"
          description="Want more customers using Facebook & Instagram ads. Join our Webinar & learn how to run your own  campaigns"
          banner={seoImage}
        />

        <div className={"section section_header section_home"}>
          <div className="section_header__bk_image">
            <img src={home_header_bk} alt="brillea smarter digital marketing" />
          </div>
          <Container>
            <Row>
              <Col
                className={"homeHeaderContent"}
                md={{ size: 7 }}
                sm={{ size: 12 }}
              >
                <h1 className="mb-3">Want more customers using Facebook &amp; Instagram ads?</h1>
                <h2 className="color_purple_light">Join our Webinar &amp; learn <br/>how to run your own  campaigns</h2>
                <p className="mb-4 mt-3"><strong>Wednesday 2nd December 3-4pm <small>GMT</small></strong></p>
                <EventbriteButton
                  className="btn btn-secondary btn-lg"
                  ebEventId={webinarEventId}>
                  Get your free ticket
                </EventbriteButton>
              </Col>
              <Col
                className="homeHeaderImage_wrapper pt-3"
                md={{ size: 5, offset: 0 }}
                sm={{ size: 12 }}
              >
              <img
                className="hidden-sm"
                src={clever_strategies_bk}
                alt="clever digital marketing strategies"
              />
              <img
                className="display-sm"
                src={clever_strategies_bk_MOB}
                alt="clever digital marketing strategies"
              />
              </Col>
            </Row>
          </Container>
        </div>

        <div className={"section section_large bk-purple section_solution"}>
          <div className="solution_image">
            <img src={brillea_cloud} alt="brillea marekting solution"/>
            <div className="solution_icons">
              <div data-aos={"zoom-in-up"} data-aos-delay="1500">
                <div className="solution_icons__round solution_icons__like">
                  <FontAwesomeIcon icon={faThumbsUp}/>
                </div>
              </div>
              <div data-aos={"zoom-in-up"} data-aos-delay="1200">
                <div className="solution_icons__round solution_icons__like_2">
                  <FontAwesomeIcon icon={faThumbsUp}/>
                </div>
              </div>
              <div data-aos={"zoom-in-up"} data-aos-delay="900">
                <div className="solution_icons__round solution_icons__heart">
                  <FontAwesomeIcon icon={faHeart}/>
                </div>
              </div>

              <div data-aos={"zoom-in-up"} data-aos-delay="600">
                <div className="solution_icons__tag solution_icons__tag_heart">
                  <FontAwesomeIcon icon={faHeart}/>
                  <div className="number_count">
                    <Count/>
                  </div>
                </div>
              </div>


              <div className="solution_icons__tag solution_icons__tag_like" data-aos={"zoom-in-up"} data-aos-delay="0">
                  <FontAwesomeIcon icon={faThumbsUp}/>
                </div>
                
            </div>
          </div>

          <Container>
            <Row>
              <Col md={{size:8, offset:4}}>
                <h2 className="h1 color_white mb-4">
                  Who you are
                </h2>
                <ul style={{color:"#FFF", marginLeft:"0px", paddingLeft:"15px"}}>
                  <li><h3 className="mb-4 color_white">A founder or employee of an early-stage startup</h3></li>
                  <li><h3 className="mb-4 color_white">Have limited to no experience running online ads but are ready to grow and get new customers through Facebook and Instagram!</h3></li>
                  <li><h3 className="mb-4 color_white">Heard of others successfully using Facecbook &amp; Instagram to grow but don't know where to start?</h3></li>
                  <li><h3 className="mb-0 color_white">Maybe you've had your fingers burnt and see how easy it is to burn through cash with little return</h3></li>
                </ul>

              </Col>
            </Row>
          </Container>
        </div>



        <div
          className={
            "section section_story section_story__marketing section_large bk-grey"
          }
          id="marketing_expert"
        >

          <Container>
            <Row>


            <Col md={{ size: 6, offset:0 }}>
                <h2 className="subTitle_1 mb-4">Running online ads <br/>can be overwhelming</h2>
              <h4 className="h2 mb-4 color_purple_dark">As a small or early stage business, you might already know that running online ads can be overwhelming and also expensive if you get it wrong! But you also know you should be doing it, and don't want to miss out...</h4>
              </Col>

              <Col md={{ size: 5, offset:1 }} className="meCards__wrapper">
                <div className="marketing_expert_bk">
                  <img src={marketing_expert} alt="digital marketing experts" />
                </div>

                <div className={"meCards"}>
                  <Card className="meCards__item meCards__item_1" style={{width:"100%"}}>
                    <CardBody>
                      <div className="meCards_item__content">

                      <h4 className="mb-3 color_purple_light">Have you ever asked yourself:</h4>
              <ul className="tick_list">
                  <li className="mb-3"><h4><FontAwesomeIcon icon={faCheck}/> How much should I be spending?</h4></li>
                  <li className="mb-3"><h4><FontAwesomeIcon icon={faCheck}/> Who should I be targeting?</h4></li>
                  <li className="mb-3"><h4><FontAwesomeIcon icon={faCheck}/> What creatives should I be using?</h4></li>
                  <li className="mb-3"><h4><FontAwesomeIcon icon={faCheck}/> What's the secret sauce?</h4></li>
                </ul>

                      </div>
                    </CardBody>
                  </Card>
                  
                </div>
              </Col>

 

            </Row>
          </Container>
        </div>




        <div className="section section_medium section_product bk-purple-dark">
          <Container>

            <Row>
              <Col md={{size:6, offset:0}} >
                  <h2 className="h1 color_white mb-5 mt-5">Join our webinar to get the answers to all of the above, and much more.</h2>
                  <h3 className="color_white mb-5">As we take you though everything you need to do to set up your campaigns for success.</h3>
              </Col>
              <Col md={{size:5, offset:1}} >
                <Card>
                  <CardHeader><h3 className="mb-0">We'll take you through</h3></CardHeader>
                <CardBody>
                  <h4 className="mt-0 mb-1 color_purple_light">Intro and Setup</h4>
                  <p className="mb-3">Why setting up your account on Facebook correctly will <strong>save you £££</strong> in the future</p>
                  <hr/>
                  <h4 className=" mt-3 mb-1 color_purple_light">Spend</h4>
                  <p className=" mb-3">How much to spend and how to spend it when you're getting started</p>
                  <hr/>
                  <h4 className=" mt-3 mb-1 color_purple_light">Audiences</h4>
                  <p className=" mb-3">How to find <strong>your target customer</strong> on Facebook and Instagram</p>
                  <hr/>
                  <h4 className=" mt-3 mb-1 color_purple_light">Creatives</h4>
                  <p className=" mb-3">What creatives should you be using, and how do you find the <strong>best ones for your business</strong>?</p>
                  <hr/>
                  <h4 className=" mt-3 mb-1 color_purple_light">Q&amp;A</h4>
                  <p className=" mb-2">Your chance to ask any questions about running ads online. These can be general, or as specific to your business as you'd like!</p>
                </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>


        <div className={"section section_medium text-center bk-purple-light section_clientLogo"}>
          <Container>
            <Row>
              <Col>
                <h2 className="color_white mt-4">So what are you waiting for?</h2>
                <EventbriteButton
                  className="btn btn-secondary btn-lg"
                  ebEventId={webinarEventId}>
                  Get your free ticket
                </EventbriteButton>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className={"section section_large section_trueup bk-grey"}
        >
          <div className="trueup_section_bk_left">
            <img
              src={trueup_section_bk_left}
              alt="brillea digital marketing experts"
            />
          </div>
          <div className="trueup_section_bk_right">
            <img src={trueup_section_bk_right} alt="brillea growth engine" />
          </div>
          <Container>
            <Row>
              <Col md={{ size: 8, offset: 2 }}>
                <h1 className="mb-4">Why you should trust us</h1>
                <h2 className="mb-4 color_purple_light">
                This webinar is brought to you by brillea and sister agency TrueUp. They're run over 10,000 Facebook &amp; Instagram ads over the last 5 years. And learnt a lot!
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 8, offset: 2 }}>

                <p className="color_purple_dark"><strong>Some of our highlights include:</strong></p>
                <ul>
                  <li>Running Telefonica's most successful Facebook marketing campaign to date</li>
                  <li>Reducing Moonpig's online cost per acquisition by over 40% within 6 weeks</li>
                  <li>Increasing the ad conversion rate by over 50% for Three Mobile's challenger brand, SMARTY</li>
                </ul>
                <p>We've taken our most important learnings and distilled them into our growth framework. which powers brillea.</p>

              </Col>
            </Row>
            <Row>
              <Col md={{ size: 8, offset: 2 }}>

              <div className="logoGrid">
                <div className="logoGrid_item">
                  <img src={MOONPIG} alt="MOONPIG TrueUp client"/>
                </div>
                <div className="logoGrid_item">
                  <img src={SMARTY} alt="SMARTY TrueUp client"/>
                </div>
                <div className="logoGrid_item">
                  <img src={LVMH} alt="LVMH TrueUp client"/>
                </div>
                <div className="logoGrid_item">
                  <img src={TELEFONICA} alt="TELEFONICA TrueUp client"/>
                </div>
              </div>
            </Col>

            </Row>
          </Container>
        </div>

      </>
    )
}
export default Dec
